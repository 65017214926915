import { withContentful } from '@/siteBuilder/renderer/contentful/withContentful'
import StickyNavigationBar, { CallToAction, Link, IStickyNavigationBarProps } from './StickyNavigationBar'

import { ISpacingObject, IAlignmentObject } from './types'
import { IStickyNavigationBarFields } from '@/contentful-types'

const ContentfulStickyNavigationBar = withContentful<IStickyNavigationBarFields, IStickyNavigationBarProps>(
  StickyNavigationBar,
  {
    Cta: ({ fields: { ctaLabel, xsCtaLabel, smCtaLabel, mdCtaLabel, lgCtaLabel, xlCtaLabel, ctaAction } }) => {
      const responsiveLabel = {
        xs: xsCtaLabel,
        sm: smCtaLabel,
        md: mdCtaLabel,
        lg: lgCtaLabel,
        xl: xlCtaLabel,
      }
      return <CallToAction label={ctaLabel} responsiveLabel={responsiveLabel} action={ctaAction} />
    },
    Link: ({ fields: { linkLabel, linkShowChevron, linkText, linkHref } }) => {
      // If text or href fields are empty, return fragment to prevent empty focused link but still allow UDS component to render a div to maintain left/right layout
      if (!linkText || !linkHref) {
        return <></>
      }
      return <Link href={linkHref} label={linkLabel} text={linkText} chevron={linkShowChevron} />
    },
    leftAlign: ({
      fields: {
        leftSegmentAlign,
        xsLeftSegmentAlign,
        smLeftSegmentAlign,
        mdLeftSegmentAlign,
        lgLeftSegmentAlign,
        xlLeftSegmentAlign,
      },
    }) => {
      let defaultValue = 'left'

      if (leftSegmentAlign) {
        defaultValue = leftSegmentAlign
      }

      const o = {
        xs: xsLeftSegmentAlign ?? defaultValue,
        sm: smLeftSegmentAlign ?? defaultValue,
        md: mdLeftSegmentAlign ?? defaultValue,
        lg: lgLeftSegmentAlign ?? defaultValue,
        xl: xlLeftSegmentAlign ?? defaultValue,
      } as IAlignmentObject

      return o
    },
    rightAlign: ({
      fields: {
        rightSegmentAlign,
        xsRightSegmentAlign,
        smRightSegmentAlign,
        mdRightSegmentAlign,
        lgRightSegmentAlign,
        xlRightSegmentAlign,
      },
    }) => {
      let defaultValue = 'right'

      if (rightSegmentAlign) {
        defaultValue = rightSegmentAlign
      }

      const o = {
        xs: xsRightSegmentAlign ?? defaultValue,
        sm: smRightSegmentAlign ?? defaultValue,
        md: mdRightSegmentAlign ?? defaultValue,
        lg: lgRightSegmentAlign ?? defaultValue,
        xl: xlRightSegmentAlign ?? defaultValue,
      } as IAlignmentObject

      return o
    },
    verticalSpacing: ({
      fields: {
        verticalSpacing,
        xsVerticalSpacing,
        smVerticalSpacing,
        mdVerticalSpacing,
        lgVerticalSpacing,
        xlVerticalSpacing,
      },
    }) => {
      let defaultValue = 2

      if (verticalSpacing) {
        defaultValue = verticalSpacing
      }

      const o = {
        xs: xsVerticalSpacing ?? defaultValue,
        sm: smVerticalSpacing ?? defaultValue,
        md: mdVerticalSpacing ?? defaultValue,
        lg: lgVerticalSpacing ?? defaultValue,
        xl: xlVerticalSpacing ?? defaultValue,
      } as ISpacingObject

      return o
    },
    horizontalSpacing: ({
      fields: {
        horizontalSpacing,
        xsHorizontalSpacing,
        smHorizontalSpacing,
        mdHorizontalSpacing,
        lgHorizontalSpacing,
        xlHorizontalSpacing,
      },
    }) => {
      let defaultValue = 2

      if (horizontalSpacing) {
        defaultValue = horizontalSpacing
      }

      const o = {
        xs: xsHorizontalSpacing ?? defaultValue,
        sm: smHorizontalSpacing ?? defaultValue,
        md: mdHorizontalSpacing ?? defaultValue,
        lg: lgHorizontalSpacing ?? defaultValue,
        xl: xlHorizontalSpacing ?? defaultValue,
      } as ISpacingObject

      return o
    },
  }
)

export default ContentfulStickyNavigationBar
