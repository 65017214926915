import { ReactElement, FC } from 'react'
import UDSStickyNavigationBar from '@telus-uds/components-community.sticky-navigation-bar'
import { Spacing, ISpacingObject, Alignment, IAlignmentObject } from './types'

export enum LAYOUT {
  DEFAULT = 'CTA | Link',
  ALTERNATE = 'Link | CTA',
}

export interface IStickyNavigationBarProps {
  Cta: ReactElement
  Link: ReactElement
  stickyVariant?: 'default' | 'alternate' | 'hidden'
  layout?: LAYOUT.DEFAULT | LAYOUT.ALTERNATE
  leftAlign?: Alignment | IAlignmentObject
  rightAlign?: Alignment | IAlignmentObject
  verticalSpacing?: Spacing | ISpacingObject
  horizontalSpacing?: Spacing | ISpacingObject
  xsLayoutReverse?: boolean
  smLayoutReverse?: boolean
  mdLayoutReverse?: boolean
  lgLayoutReverse?: boolean
  xlLayoutReverse?: boolean
  workWithViewport?: boolean
}

const StickyNavigationBar = ({
  Cta,
  Link,
  stickyVariant = 'default',
  layout = LAYOUT.DEFAULT,
  leftAlign = { xs: 'center', sm: 'left', md: 'left', lg: 'left', xl: 'left' },
  rightAlign = { xs: 'center', sm: 'left', md: 'left', lg: 'left', xl: 'left' },
  verticalSpacing = 2,
  horizontalSpacing = { xs: 3, sm: 2, md: 2, lg: 2, xl: 2 },
  xsLayoutReverse = false,
  smLayoutReverse = false,
  mdLayoutReverse = false,
  lgLayoutReverse = false,
  xlLayoutReverse = false,
  workWithViewport = true,
}: IStickyNavigationBarProps) => {
  const left = layout === LAYOUT.DEFAULT ? Cta : Link
  const right = layout === LAYOUT.DEFAULT ? Link : Cta
  return (
    <UDSStickyNavigationBar
      left={left}
      right={right}
      leftAlign={leftAlign}
      rightAlign={rightAlign}
      vertical={verticalSpacing}
      horizontal={horizontalSpacing}
      stickyVariant={stickyVariant}
      xsReverse={xsLayoutReverse}
      smReverse={smLayoutReverse}
      mdReverse={mdLayoutReverse}
      lgReverse={lgLayoutReverse}
      xlReverse={xlLayoutReverse}
      workWithViewport={workWithViewport}
    />
  )
}

interface ICallToActionProps {
  label: string
  action: string | (() => void)
  responsiveLabel: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
  }
}

interface ILinkProps {
  label: string
  href: string
  text: string
  chevron: boolean
}

export const CallToAction = UDSStickyNavigationBar.CallToAction as FC<ICallToActionProps>

export const Link = UDSStickyNavigationBar.Link as FC<ILinkProps>

export default StickyNavigationBar
